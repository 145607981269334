<template>
  <el-dialog
    v-model="visible"
    :title="'code' in needUpdateInfo ? '修改banner' : '新增banner'"
    width="40%"
    :before-close="handleClose"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="needUpdateInfo"
      label-width="120px"
      :rules="rules"
      ref="elForm"
      v-loading.fullscreen.lock="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="banner名称" prop="name">
        <el-input v-model="needUpdateInfo.name" style="width: 70%;" placeholder="请输入banner名称" />
      </el-form-item>
      <el-form-item label="图片" prop="url">
        <el-upload
          accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :multiple="false"
          :http-request="changeImage"
        >
          <img v-if="needUpdateInfo.url" :src="needUpdateInfo.url" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="needUpdateInfo.remarks"> </el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input-number v-model="needUpdateInfo.sort" :min="0" :max="1000"></el-input-number>
      </el-form-item>
      <el-form-item label="关联页面" prop="bannerPage">
        <el-input v-model="needUpdateInfo.bannerPage" type="textarea" style="width: 70%;"></el-input>
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">确定</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { headers, client, httpPre } from '../../../../lin/util/uploadImg.js'
import { defineComponent, computed, ref, reactive } from 'vue'
import API from '../../service/api'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    needUpdateInfo: Object,
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })
    const form = reactive({
      loadText: '正在火速加载数据...',
      loading: false,
      img: [],
    })
    const handleRemove = async () => {
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称，例:upload/2023-02/b35aae063be1e198207bc8dc778.jpg
        await client().delete(props.needUpdateInfo.url.substring(httpPre.length))
        props.needUpdateInfo.url = ''
      } catch (error) {}
    }

    const changeImage = file => {
      console.log(props.needUpdateInfo.url)
      if (props.needUpdateInfo.url) {
        handleRemove()
      }
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img.push(file)
      props.needUpdateInfo.url = ''
      let filesSplit = file.file.name.split('.')
      let fileName =
        file.file.name.substring(0, file.file.name.length - filesSplit[filesSplit.length - 1].length - 1) +
        '_' +
        Date.now() +
        '.' +
        filesSplit[filesSplit.length - 1]
      client()
        .put('/miniprogram/banner/' + fileName, file.file, { headers })
        .then(res => {
          props.needUpdateInfo.url = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }

    const rules = reactive({
      name: [{ required: true, message: '请填写banner名称', trigger: 'change' }],
      url: [{ required: true, message: '请上传图片', trigger: 'change' }],
    })

    const elForm = ref(null)

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let saveData = {
            name: props.needUpdateInfo.name,
            url: props.needUpdateInfo.url,
            sort: props.needUpdateInfo.sort,
            remarks: props.needUpdateInfo.remarks,
            bannerPage: props.needUpdateInfo.bannerPage,
          }
          if (Reflect.has(props.needUpdateInfo, 'code')) {
            updateSpuInfo({ ...saveData, code: props.needUpdateInfo.code })
          } else {
            saveSpuInfo(saveData)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    //新增数据
    const updateSpuInfo = data => {
      API.updateBanner(data)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    //修改数据
    const saveSpuInfo = data => {
      API.insertBanner(data)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('保存成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      elForm,
      rules,
      changeImage,
      form,
      handleSubmit,
    }
  },
})
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
